import { Color, Icon, Row, RowProps } from '@snowflake/core-ui';
import { default as React, RefObject } from 'react';
import { MarketingParams, SignupParams } from '../../../utils/ParseQueryParams';
import cookies from 'browser-cookies';
import { MarketingButton } from './MarketingButton';
import * as configs from '../../../utils/configurations';
import { BEACH_COOKIE } from '../../../utils/InternalHeaders';

export interface BaseCardProps {
  signupParams: SignupParams;
  marketingParams: MarketingParams;
  formId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recaptchaRef: RefObject<any>;
}

export const FormRow = (props: RowProps) => <Row marginTop={16} {...props} />;

export const FormButton = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: React.EventHandler<any>;
  children: React.ReactNode;
}) => {
  return (
    <Row align="center" verticalAlign="top" marginTop={24}>
      <MarketingButton onClick={props.onClick}>{props.children}</MarketingButton>
    </Row>
  );
};

export const exclamationIcon = <Icon icon="warning" size={32} color={Color.Red50} />;
export const checkmarkIcon = <Icon icon="checkmark" size={32} color={Color.Blue50} />;

export function sleep(millis: number) {
  return new Promise(resolve => setTimeout(resolve, millis));
}

export function DotsLoadingIndicator() {
  return (
    <div className="dot-animation">
      <div className="root">
        <li className="dot" />
        <li className="dot" />
        <li className="dot" />
      </div>
    </div>
  );
}

export function getCurrentRootUrl(): string {
  let url = `${window.location.protocol}//${window.location.hostname}`;
  if (configs.getBuildEnv() !== 'production') {
    url += `:${window.location.port}`;
  }
  return url;
}

export async function getRecaptchaToken(recaptchaRef: React.RefObject<any>): Promise<string> {
  if (configs.getDeploymentGroup() === 'DEV_DEPLOYMENTS') {
    return 'skipped';
  }

  return recaptchaRef.current.executeAsync();
}

// skipping recaptcha on preprod / canary for integration tests.
export function getShouldSkipRecaptcha(): boolean {
  if (configs.getDeploymentGroup() === 'DEV_DEPLOYMENTS') {
    return true;
  }

  const beachCookieValue = cookies.get(BEACH_COOKIE) || null;
  if (beachCookieValue === 'canary' && window.location.search.includes('integrationTest=1')) {
    return true;
  }

  return false;
}
