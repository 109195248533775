export enum MarketingTextType {
  BASIC = 'basic',
  OMIT = 'omit',
  LISTING = 'listing',
}

interface BaseRenderingOptions {
  marketingTextType: MarketingTextType;
  mainMarketingTitle?: JSX.Element;
  marketingInfoList?: JSX.Element[];
  cardMarketingTitle?: JSX.Element;
  hideCertifications?: boolean;
  skipSurvey?: boolean;
  successTitle?: string;
  successSubtitle?: JSX.Element;
  awsAddPaymentLink?: JSX.Element;
  showSalesAccessCheckbox?: boolean;
  showEmailAgreementCheckbox?: boolean;
  hideLoginLink?: boolean;
  pageNotFound?: boolean;
}

interface RenderingOptionsBasicMarketingText extends BaseRenderingOptions {
  marketingTextType: MarketingTextType.BASIC;
  mainMarketingTitle: JSX.Element;
  marketingInfoList: JSX.Element[];
  cardMarketingTitle: JSX.Element;
}

interface RenderingOptionsOmitMarketingText extends BaseRenderingOptions {
  marketingTextType: MarketingTextType.OMIT;
}

interface RenderingOptionsListingMarketingText extends BaseRenderingOptions {
  marketingTextType: MarketingTextType.LISTING;
  cardMarketingTitle: JSX.Element;
}

export type RenderingOptions =
  | RenderingOptionsBasicMarketingText
  | RenderingOptionsOmitMarketingText
  | RenderingOptionsListingMarketingText;
