import { useState } from 'react';
import { getSignupFlowTypeWithoutListener } from './configurations';
import { SignupFlowType } from '@signup/shared';

export const useSignupFlowType = () => {
  const [signupFlowType, setSignupFlowType] = useState<SignupFlowType>(
    getSignupFlowTypeWithoutListener(),
  );
  window.addEventListener('signupFlowTypeChange', () =>
    setSignupFlowType(getSignupFlowTypeWithoutListener()),
  );
  return signupFlowType;
};
