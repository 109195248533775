import {
  Block,
  Checkbox,
  Color,
  Column,
  FormLayout,
  FormTextRow,
  Input,
  Radio,
  RadioGroup,
  Row,
  SearchableSelector,
  Selector,
  Switch,
  Text,
  TextLink,
  useTelemetry,
  ValidationState,
} from '@snowflake/core-ui';
import {
  Cloud,
  countries,
  countriesKeyedByIsoCode,
  Edition,
  isSuppressionErrorCode,
  SignupApiErrorCode,
  SignupEventType,
  SignupFormOneFields,
  validateSnowflakeEmail,
  validateInput,
  invalidCharactersRegex,
  validateCompanyName,
  SignupFlowType,
} from '@signup/shared';
import { default as React, FormEvent, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeploymentOption } from '../../../../models';
import { useSignupPageContext } from '../../../../pages/SignupContext';
import { SignupEventName, SignupSteps } from '../../../../utils/SignupLogger';
import { useLogOnClose } from '../../../UseLogOnClose';
import { CardTemplate } from '../../shared/CardTemplate';
import {
  BaseCardProps,
  FormButton,
  FormRow,
  getShouldSkipRecaptcha,
  sleep,
} from '../../shared/shared';
import { ThankYouCard } from '../../ThankYouCard/ThankYouCard';
import { CloudLogoContainer } from './CloudLogoContainer';
import { LoginLink } from '../../../LoginLink';
import { useCoordinates } from './UseCoordinates';
import { useRoles } from './roles';
import { SignupAgreement } from './SignupAgreement';
import { isCountryISOCodeInUS, isCountryISOCodeInGDPR } from '../../utils/SignupCountryUtils';
import * as configs from '../../../../utils/configurations';
import { SignupApi } from '../../../../api/signupApi';
import { useEmailValidator } from '../../utils/validationUtils';
import { useDeploymentMetadata } from '../../utils/deploymentUtils';
import { EmailAgreement } from '../../shared/EmailAgreement';
import { getAwsMarketplaceType } from '../../../../utils/signupFlowTypeUtils';
import { useSignupFlowType } from '../../../../utils/useSignupFlowType';

export const SignupCard = (props: BaseCardProps) => {
  // Form options
  const [countryIsoCode, setCountryIsoCode] = useState<string>('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [termsAgreement, setTermsAgreement] = useState(false);
  const [salesEngineerAgreement, setSalesEngineerAgreement] = useState(false);
  const [optOutEmailAgreement, setOptOutEmailAgreement] = useState<boolean | undefined>(undefined);
  const [optInEmailAgreement, setOptInEmailAgreement] = useState(false);
  const [givenEmailOptInChoice, setGivenEmailOptInChoice] = useState(false);
  const [filterVisibleRegions, setFilterVisibleRegions] = useState(true);
  const coordinates = useCoordinates();

  // First Form Validation
  const [firstNameBlurred, setFirstNameBlurred] = useState(false);
  const [lastNameBlurred, setLastNameBlurred] = useState(false);
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [companyBlurred, setCompanyBlurred] = useState(false);
  const [countryBlurred, setCountryBlurred] = useState(false);
  const [roleBlurred, setRoleBlurred] = useState(false);
  const [emailValid, setEmailValid] = useState<ValidationState>(ValidationState.NONE);

  // Second Form Validation
  const [cloudBlurred, setCloudBlurred] = useState(false);
  const [regionBlurred, setRegionBlurred] = useState(false);
  const [termsBlurred, setTermsBlurred] = useState(false);
  const [salesAssistedBlurred, setSalesAssistedBlurred] = useState(false);

  //Localization -- message formatter
  const { formatMessage } = useIntl();
  //Set up Telemetry
  const { logAction, logEvent } = useTelemetry();

  const roles = useRoles();

  React.useEffect(() => {
    // Set country based on IP lookup result
    const ipIsoCode = configs.getIsoCode();
    const matchedIsoCode: string = ipIsoCode && countriesKeyedByIsoCode[ipIsoCode] ? ipIsoCode : '';
    setCountryIsoCode(matchedIsoCode);
  }, []);

  // GET param-drivable form options
  const [edition, setEdition] = useState<Edition>(
    props.signupParams.plan || props.signupParams.planp || Edition.ENTERPRISE,
  );
  const [cloud, setCloud] = useState<Cloud | ''>(
    props.signupParams.cloud || props.signupParams.cloudp || '',
  );
  const [region, setRegion] = useState<string>(
    props.signupParams.region || props.signupParams.regionp || '',
  );

  const getSuggestedRegion = useCallback(
    (regionList: DeploymentOption[]) => {
      let suggestedRegion = '';

      // We only suggest a region if we know where the user is (coordinates exist)
      // Picking the first entry for the cloud provider's available regions should be the closest since this list should be sorted.
      // If the cloud provider has no visible regions then the first entry will have a disabled flag set to true, in which case we set a default of ''
      // A cloud should always have an available region, but just in case this value is undefined we set a default of ''
      if (coordinates && regionList.length >= 1 && !regionList[0].disabled) {
        suggestedRegion = regionList[0].value ?? '';
      }

      return suggestedRegion;
    },
    [coordinates],
  );

  const { showVisibleRegionsFilter, regionSelectList, visibleRegions } = useDeploymentMetadata({
    props,
    email,
    filterVisibleRegions,
  });

  React.useEffect(() => {
    // If the region is determined in the url, the logic in this hook does not need to run
    if (props.signupParams.region || props.signupParams.regionp) {
      return;
    }

    // If a cloud has been chosen, then reset the suggested region
    if (cloud) {
      setRegion(getSuggestedRegion(regionSelectList[cloud]));
    }
  }, [
    cloud,
    regionSelectList,
    getSuggestedRegion,
    props.signupParams.region,
    props.signupParams.regionp,
  ]);

  // Page State
  const [step, setStep] = useState<1 | 2>(1);
  // For specific server errors on async requests
  const [errorCode, setErrorCode] = useState<SignupApiErrorCode | undefined>(undefined);
  // For client side errors in event handlers to trigger the event boundary
  const [error, setError] = useState<Error | undefined>(undefined);
  const [accountCreationFinished, setAccountCreationFinished] = useState(false);
  const [formOneStartTime, setFormOneStartTime] = useState<number>(0);
  const [formTwoStartTime, setFormTwoStartTime] = useState<number | undefined>(undefined);
  const [currentStepLogInfo, setCurrentStepLogInfo] = useState({
    currentStep: SignupSteps.INITIAL_LOAD as string,
    startTime: Date.now() / 1000,
  });

  const { formSubmitted, docsLocale, renderingOptions } = useSignupPageContext();
  const handleFormOneFocus = () => {
    if (formOneStartTime === 0) {
      const currTime = Date.now() / 1000;
      setFormOneStartTime(currTime);
      setCurrentStepLogInfo({ currentStep: SignupSteps.FIRST_FORM, startTime: currTime });
    }
  };

  //track the amount of time spent on each form if the user closes the site without completing the form
  useLogOnClose({
    message: 'exit_signup_' + currentStepLogInfo.currentStep,
    startTime: currentStepLogInfo.startTime,
  });

  const signupFlowType = useSignupFlowType();

  const isSalesAssistedFlow = signupFlowType === SignupFlowType.SalesAssisted;

  // Validate sales assisted url. Owner parameter should always exist, eng parameter is optional.
  React.useMemo(() => {
    if (isSalesAssistedFlow) {
      if (
        !(
          props.signupParams.owner &&
          validateSnowflakeEmail.test((props.signupParams.owner as string) + '@snowflake.com')
        )
      ) {
        setErrorCode(SignupApiErrorCode.INVALID_SALES_ASSISTED_URL);
        logEvent({
          event: SignupEventName.SIGNUP_SALES_ASSISTED_OWNER_ERROR,
          type: SignupEventType.UI_ERROR,
          data: {
            owner: props.signupParams.owner,
            eng: props.signupParams.eng,
          },
          interaction: false,
        });
      }

      if (
        props.signupParams.eng &&
        !validateSnowflakeEmail.test(props.signupParams.eng as string)
      ) {
        setErrorCode(SignupApiErrorCode.INVALID_SALES_ASSISTED_URL);
        logEvent({
          event: SignupEventName.SIGNUP_SALES_ASSISTED_ENG_ERROR,
          type: SignupEventType.UI_ERROR,
          data: {
            owner: props.signupParams.owner,
            eng: props.signupParams.eng,
          },
          interaction: false,
        });
      }
    }
  }, [props.signupParams.owner, props.signupParams.eng, isSalesAssistedFlow, logEvent]);

  // Form Handling and Validation
  const firstFormValidationErrors = React.useMemo(() => {
    const invalidFields = [];

    !role && invalidFields.push('role');
    !countryIsoCode && invalidFields.push('country');
    !validateInput(firstName) && invalidFields.push('first_name');
    !validateInput(lastName) && invalidFields.push('last_name');
    !(email.trim().length && emailValid === ValidationState.VALID) && invalidFields.push('email');
    (!company.trim().length ||
      company.trim().length >= 40 ||
      company.trim().toLowerCase() === 'null' ||
      invalidCharactersRegex.test(company)) &&
      invalidFields.push('company');
    return invalidFields;
  }, [countryIsoCode, firstName, lastName, email, company, emailValid, role]);

  const secondFormValidationErrors = React.useMemo(() => {
    const invalidFields = [];

    !edition.length && invalidFields.push('edition');
    !region.length && invalidFields.push('region');
    !cloud.length && invalidFields.push('cloud');
    !termsAgreement && invalidFields.push('terms');
    props.signupParams.eng &&
      !salesEngineerAgreement &&
      invalidFields.push('SalesEngineerAgreement');

    return invalidFields;
  }, [edition, region, cloud, termsAgreement, salesEngineerAgreement, props.signupParams.eng]);

  async function handleSubmit(e: FormEvent) {
    if (e.preventDefault !== undefined) e.preventDefault();

    if (secondFormValidationErrors.length) {
      setCloudBlurred(true);
      setRegionBlurred(true);
      setTermsBlurred(true);
      setSalesAssistedBlurred(true);

      logAction(
        SignupEventName.SIGNUP_FORM_TWO_VALIDATION_ERROR_ON_SUBMIT,
        'ui_click',
        'SignupCard',
        {
          secondFormValidationErrors,
        },
      );
    } else {
      const recaptchaToken = getShouldSkipRecaptcha()
        ? 'skipped'
        : await props.recaptchaRef.current.executeAsync();

      formSubmitted.setValue(true);
      configs.getDataLayer().push({ event: 'self-service trial signup' });

      const formTwoCompletionTime = formTwoStartTime ? Date.now() / 1000 - formTwoStartTime : 0;

      const thankYouPageStartTime = Date.now() / 1000;
      setCurrentStepLogInfo({
        currentStep: SignupSteps.THANK_YOU_PAGE,
        startTime: thankYouPageStartTime,
      });

      try {
        const options = SignupApi.prepareSignupFields({
          firstName,
          lastName,
          email,
          company,
          role,
          edition,
          cloud: cloud as Cloud,
          region,
          awsType: getAwsMarketplaceType(),
          signupParams: props.signupParams,
          formId: props.formId,
          countryIsoCode,
          recaptchaToken,
          optOutEmailAgreement,
          givenEmailOptInChoice,
          marketingParams: props.marketingParams,
          formTwoCompletionTime,
          optInEmailAgreement,
        });
        logAction(SignupEventName.SIGNUP_FORM_TWO_COMPLETE, 'ui_click', 'SignupCard', {
          ...options,
          ...(options.recaptchaToken && { recaptchaToken: 'REDACTED' }),
          ...(options.ampt && { ampt: 'REDACTED' }),
          referrerUrl: configs.getReferrerUrl(),
        });
        const { res, headers } = await SignupApi.createTrial(options);

        if (res.success !== true) {
          await sleep(1000);
          setErrorCode(res.errorCode);
          if (isSuppressionErrorCode(res.errorCode)) {
            logEvent({
              event: SignupEventName.SIGNUP_SUPPRESSED,
              type: SignupEventType.UI_RESPONSE_SUCCESS,
              data: {
                thankYouResponseDuration: Date.now() / 1000 - thankYouPageStartTime,
                suppressionReason: res.errorCode,
              },
              interaction: false,
            });
          } else {
            logEvent({
              event: SignupEventName.SIGNUP_ACCOUNT_CREATION_ERROR,
              type: SignupEventType.UI_RESPONSE_ERROR,
              data: {
                thankYouResponseDuration: Date.now() / 1000 - thankYouPageStartTime,
                txnId: headers.get('X-TXN-ID'),
              },
              interaction: false,
            });
          }
        }
        setAccountCreationFinished(true);
        if (isSalesAssistedFlow) {
          logEvent({
            event: SignupEventName.SIGNUP_SALES_ASSISTED_SUCCESS,
            type: SignupEventType.UI_RESPONSE_SUCCESS,
            data: {
              thankYouResponseDuration: Date.now() / 1000 - thankYouPageStartTime,
            },
            interaction: false,
          });
        } else {
          logEvent({
            event: SignupEventName.SIGNUP_SUCCESS,
            type: SignupEventType.UI_RESPONSE_SUCCESS,
            data: {
              thankYouResponseDuration: Date.now() / 1000 - thankYouPageStartTime,
            },
            interaction: false,
          });
        }
      } catch (err) {
        await sleep(1000);
        setErrorCode(SignupApiErrorCode.GENERIC_ERROR);
        logEvent({
          event: SignupEventName.SIGNUP_ACCOUNT_CREATION_ERROR,
          type: SignupEventType.UI_RESPONSE_ERROR,
          data: {
            thankYouResponseDuration: Date.now() / 1000 - thankYouPageStartTime,
            txnId: 'null',
          },
          interaction: false,
        });
      }
    }
  }

  async function handleContinue(e: FormEvent) {
    if (e.preventDefault !== undefined) e.preventDefault();

    if (firstFormValidationErrors.length) {
      setFirstNameBlurred(true);
      setLastNameBlurred(true);
      setEmailBlurred(true);
      setCompanyBlurred(true);
      setRoleBlurred(true);
      setCountryBlurred(true);

      logAction(
        SignupEventName.SIGNUP_FORM_ONE_VALIDATION_ERROR_ON_SUBMIT,
        'ui_click',
        'SignupCard',
        {
          firstFormValidationErrors,
        },
      );
    } else {
      try {
        const formOneCompletionTime = Date.now() / 1000 - formOneStartTime;
        const options: SignupFormOneFields = {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: email.trim().toLowerCase(),
          company: company.trim(),
          role: role,
          country: countriesKeyedByIsoCode[countryIsoCode].value,
          ...(optOutEmailAgreement !== undefined && { optOutEmailAgreement }), // should only be undefined for 'United States'
          ...(givenEmailOptInChoice && { givenEmailOptInChoice, optInEmailAgreement }),
          formId: props.formId,
          formOneCompletionTime,
        };

        logAction(SignupEventName.SIGNUP_FORM_ONE_COMPLETE, 'ui_click', 'SignupCard', {
          ...options,
        });

        const currTime = Date.now() / 1000;
        setFormTwoStartTime(currTime);
        setStep(2);
        setCurrentStepLogInfo({ currentStep: SignupSteps.SECOND_FORM, startTime: currTime });
      } catch (err) {
        setError(err);
      }
    }
  }

  // Form
  const countryOptions = countries.map(country => {
    return {
      value: country.isoCode,
      label: country.label,
    };
  });

  const updateEmailAgreementState = (selectedCountryisoCode: string) => {
    if (renderingOptions.showEmailAgreementCheckbox) {
      setOptOutEmailAgreement(undefined);
      setGivenEmailOptInChoice(true);
      return;
    }
    if (isCountryISOCodeInUS(selectedCountryisoCode)) {
      setOptOutEmailAgreement(undefined);
      setGivenEmailOptInChoice(false);
    } else if (isCountryISOCodeInGDPR(selectedCountryisoCode)) {
      setOptOutEmailAgreement(undefined);
      setGivenEmailOptInChoice(true);
    } else {
      optOutEmailAgreement === undefined && setOptOutEmailAgreement(false);
      setGivenEmailOptInChoice(false);
    }
  };

  const { validateEmailHelper } = useEmailValidator();

  const renderFirstStep = () => {
    return (
      <>
        <Block onFocus={handleFormOneFocus}>
          <Row>
            <Input
              value={firstName}
              placeholder={formatMessage({ id: 'First Name' }) + '*'}
              onChange={e => setFirstName(e.currentTarget.value)}
              validation={val => validateInput(val)}
              required={firstNameBlurred}
              onBlur={() => setFirstNameBlurred(true)}
            />
          </Row>
          <FormRow>
            <Input
              value={lastName}
              placeholder={formatMessage({ id: 'Last Name' }) + '*'}
              onChange={e => setLastName(e.currentTarget.value)}
              validation={val => validateInput(val)}
              required={lastNameBlurred}
              onBlur={() => setLastNameBlurred(true)}
            />
          </FormRow>
          <FormRow>
            <Input
              value={email}
              placeholder={formatMessage({ id: 'Company Email' }) + '*'}
              onChange={e => setEmail(e.currentTarget.value)}
              validation={validateEmailHelper}
              required={emailBlurred}
              onBlur={() => {
                logAction(SignupEventName.SIGNUP_VALIDATE_EMAIL, 'ui_keyboard', 'SignupCard', {
                  emailValidationState: emailValid,
                  email,
                });
                setEmailBlurred(true);
              }}
              onValidationChange={setEmailValid}
            />
          </FormRow>
          <FormRow>
            <Input
              value={company}
              placeholder={formatMessage({ id: 'Company Name' }) + '*'}
              onChange={e => setCompany(e.currentTarget.value)}
              validation={validateCompanyName}
              required={companyBlurred}
              onBlur={() => setCompanyBlurred(true)}
            />
          </FormRow>
          <FormRow>
            <Block width={'100%'}>
              <Selector
                options={roles}
                value={role}
                placeholder={formatMessage({ id: 'Role' }) + '*'}
                onChange={e => {
                  const roleValue = e.currentTarget.value;
                  setRole(roleValue);
                }}
                validation={val => !!val}
                required={roleBlurred}
                onBlur={() => setRoleBlurred(true)}
                width={'100%'}
              />
            </Block>
          </FormRow>
          <FormRow>
            <Block width={'100%'}>
              <SearchableSelector
                options={countryOptions}
                value={countryIsoCode}
                placeholder={formatMessage({ id: 'Country' }) + '*'}
                data-testid="country-selector"
                onChange={e => {
                  const countryisoCode = e.currentTarget.value;
                  setCountryIsoCode(countryisoCode);
                  updateEmailAgreementState(countryisoCode); //update email agreement options based on the country
                }}
                validation={val => !!val}
                required={countryBlurred}
                onBlur={() => setCountryBlurred(true)}
                width={'100%'}
                maxResults={100}
                filterPlaceholder={formatMessage({ id: 'Filter' })}
              />
            </Block>
          </FormRow>
          <Row>
            <EmailAgreement
              optInEmailAgreement={optInEmailAgreement}
              setOptInEmailAgreement={setOptInEmailAgreement}
              optOutEmailAgreement={optOutEmailAgreement}
              setOptOutEmailAgreement={setOptOutEmailAgreement}
              countryIsoCode={countryIsoCode}
            />
          </Row>

          <Row align="center" verticalAlign="top" marginTop={12} marginBottom={12}>
            <SignupAgreement countryIsoCode={countryIsoCode} />
          </Row>
          <FormButton onClick={handleContinue}>
            <FormattedMessage id="Continue" />
          </FormButton>
          {!renderingOptions.hideLoginLink && <LoginLink />}
          {!!renderingOptions.awsAddPaymentLink && renderingOptions.awsAddPaymentLink}
        </Block>
      </>
    );
  };

  const renderEdition = () => {
    const editionLabelAndInfo = {
      [Edition.STANDARD]: {
        label: formatMessage({ id: 'Standard' }),
        info: (
          <Text color={Color.Gray70} cursor="pointer">
            <FormattedMessage id="A strong balance between features, level of support, and cost." />
          </Text>
        ),
      },
      [Edition.ENTERPRISE]: {
        label: formatMessage({ id: 'Enterprise' }),
        info: (
          <Text color={Color.Gray70} cursor="pointer">
            <FormattedMessage
              id="Standard plus 90-day <timetravel>time travel</timetravel>, <multicluster>multi-cluster warehouses</multicluster>, and <matviews>materialized views</matviews>."
              values={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                timetravel: function TimeTravel(...chunks: any[]) {
                  return (
                    <TextLink
                      href={`https://docs.snowflake.com/${docsLocale.value}/user-guide/data-time-travel.html`}
                      target="_blank"
                    >
                      {chunks}
                    </TextLink>
                  );
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                multicluster: function MultiCluster(...chunks: any[]) {
                  return (
                    <TextLink
                      href={`https://docs.snowflake.com/${docsLocale.value}/user-guide/warehouses-multicluster.html`}
                      target="_blank"
                    >
                      {chunks}
                    </TextLink>
                  );
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                matviews: function MatViews(...chunks: any[]) {
                  return (
                    <TextLink
                      href={`https://docs.snowflake.com/${docsLocale.value}/user-guide/views-materialized.html`}
                      target="_blank"
                    >
                      {chunks}
                    </TextLink>
                  );
                },
              }}
            />
          </Text>
        ),
      },
      [Edition.BUSINESS_CRITICAL]: {
        label: formatMessage({ id: 'Business Critical' }),
        info: (
          <Text color={Color.Gray70} cursor="pointer">
            <FormattedMessage id="Enterprise plus enhanced security, data protection, and database failover/fallback." />
          </Text>
        ),
      },
    };

    const editionSelectionOptions = (
      <>
        <Block marginBottom={8}>
          <Text color={Color.Gray70}>
            {formatMessage({ id: 'Choose your Snowflake edition' }) + '*'}
          </Text>
        </Block>
        <Row align="left" verticalAlign="top">
          <RadioGroup name="edition" value={edition} aria-label="Snowflake edition">
            <Row align="left" verticalAlign="top" marginBottom={8}>
              <Column align="left" verticalAlign="top" marginRight={8}>
                <Radio
                  value={Edition.STANDARD}
                  id="edition-standard"
                  onChange={_e => {
                    logAction(SignupEventName.SIGNUP_SELECT_EDITION, 'ui_selection', 'SignupCard', {
                      oldEdition: edition,
                      newEdition: Edition.STANDARD,
                    });
                    setEdition(Edition.STANDARD);
                  }}
                />
              </Column>
              <Column align="left" verticalAlign="top">
                <label htmlFor="edition-standard">
                  <Text weight="semi-bold">{editionLabelAndInfo[Edition.STANDARD].label}</Text>
                  {editionLabelAndInfo[Edition.STANDARD].info}
                </label>
              </Column>
            </Row>
            <Row align="left" verticalAlign="top" marginBottom={8}>
              <Column align="left" verticalAlign="top" marginRight={8}>
                <Radio
                  value={Edition.ENTERPRISE}
                  id="edition-enterprise"
                  onChange={_e => {
                    logAction(SignupEventName.SIGNUP_SELECT_EDITION, 'ui_selection', 'SignupCard', {
                      oldEdition: edition,
                      newEdition: Edition.ENTERPRISE,
                    });
                    setEdition(Edition.ENTERPRISE);
                  }}
                />
              </Column>
              <Column align="left" verticalAlign="top">
                <label htmlFor="edition-enterprise">
                  <Text weight="semi-bold" cursor="pointer">
                    {editionLabelAndInfo[Edition.ENTERPRISE].label}
                  </Text>
                  {editionLabelAndInfo[Edition.ENTERPRISE].info}
                </label>
              </Column>
            </Row>
            <Row align="left" verticalAlign="top">
              <Column align="left" verticalAlign="top" marginRight={8}>
                <Radio
                  value={Edition.BUSINESS_CRITICAL}
                  id="edition-business-critical"
                  onChange={_e => {
                    logAction(SignupEventName.SIGNUP_SELECT_EDITION, 'ui_selection', 'SignupCard', {
                      oldEdition: edition,
                      newEdition: Edition.BUSINESS_CRITICAL,
                    });
                    setEdition(Edition.BUSINESS_CRITICAL);
                  }}
                />
              </Column>
              <Column align="left" verticalAlign="top">
                <label htmlFor="edition-business-critical">
                  <Text weight="semi-bold" cursor="pointer">
                    {editionLabelAndInfo[Edition.BUSINESS_CRITICAL].label}
                  </Text>
                  {editionLabelAndInfo[Edition.BUSINESS_CRITICAL].info}
                </label>
              </Column>
            </Row>
          </RadioGroup>
        </Row>
      </>
    );

    const editionLockedOption = (edition: Edition) => {
      return (
        <>
          <Block marginBottom={8}>
            <Text color={Color.Gray70}>
              <FormattedMessage id="Snowflake edition" />
            </Text>
          </Block>
          <Row align="left" verticalAlign="top">
            <Column align="left" verticalAlign="top">
              <Text weight="semi-bold">{editionLabelAndInfo[edition].label}</Text>
              {editionLabelAndInfo[edition].info}
            </Column>
          </Row>
        </>
      );
    };

    return !props.signupParams.plan
      ? editionSelectionOptions
      : editionLockedOption(props.signupParams.plan);
  };

  const renderCloud = () => {
    const logos: { [cloud in Cloud]: { src: string; title: string } } = {
      [Cloud.AWS]: {
        src: '/static/images/cloud_provider_choices/aws-logo.svg',
        title: formatMessage({ id: 'Amazon Web Services' }),
      },
      [Cloud.GCP]: {
        src: '/static/images/cloud_provider_choices/gcp-logo.png',
        title: formatMessage({ id: 'Google Cloud Platform' }),
      },
      [Cloud.AZURE]: {
        src: '/static/images/cloud_provider_choices/microsoft-logo.svg',
        title: formatMessage({ id: 'Microsoft Azure' }),
      },
    };

    const handleSelect = (selectedCloud: Cloud) => {
      logAction(SignupEventName.SIGNUP_SELECT_CLOUD, 'ui_selection', 'SignupCard', {
        oldCloud: cloud,
        newCloud: selectedCloud,
      });
      const regionsHasLoaded = !props.signupParams.listing || visibleRegions;
      if (regionsHasLoaded && selectedCloud !== cloud) {
        const regionList = regionSelectList[selectedCloud];
        setRegion(getSuggestedRegion(regionList));
      }
      setCloud(selectedCloud);
    };

    const cloudOptions = (
      <RadioGroup name="cloud" aria-label="Cloud provider">
        <Block display="flex" justifyContent="space-between">
          <CloudLogoContainer
            src={logos[Cloud.AZURE].src}
            title={logos[Cloud.AZURE].title}
            value={Cloud.AZURE}
            selected={cloud === Cloud.AZURE}
            handleSelect={handleSelect}
          />
          <CloudLogoContainer
            src={logos[Cloud.AWS].src}
            title={logos[Cloud.AWS].title}
            value={Cloud.AWS}
            selected={cloud === Cloud.AWS}
            handleSelect={handleSelect}
          />
          <CloudLogoContainer
            src={logos[Cloud.GCP].src}
            title={logos[Cloud.GCP].title}
            value={Cloud.GCP}
            selected={cloud === Cloud.GCP}
            handleSelect={handleSelect}
          />
        </Block>
      </RadioGroup>
    );

    // this is a function because cloud may not be specified
    const renderLockedLogo = () => (
      <CloudLogoContainer
        src={cloud ? logos[cloud].src : ''}
        title={cloud ? logos[cloud].title : ''}
        value={cloud}
        selected={true}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleSelect={() => {}}
      />
    );

    const showMustSelectCloudMessage = !cloud && cloudBlurred;
    const mustSelectCloudMessage = (
      <Text color={Color.Red50} size="small">
        <FormattedMessage id="Must select a cloud" />
      </Text>
    );
    if (getAwsMarketplaceType() !== undefined) {
      // aws flows
      return <Block marginTop={24} />;
    } else {
      // sales-assisted flows may have cloud selected
      const locked = props.signupParams.cloud;
      return (
        <>
          <Block marginTop={24} marginBottom={24}>
            <Block marginBottom={8}>
              <Text color={Color.Gray70}>
                {locked
                  ? formatMessage({ id: 'Cloud provider' })
                  : formatMessage({ id: 'Choose your cloud provider' }) + '*'}
              </Text>
              {showMustSelectCloudMessage ? mustSelectCloudMessage : null}
            </Block>
            <Block>{locked ? renderLockedLogo() : cloudOptions}</Block>
          </Block>
        </>
      );
    }
  };

  const renderRegion = () => {
    const regionSelectionOptions = (
      <>
        {props.signupParams.listing && showVisibleRegionsFilter && (
          <Row marginBottom={16} verticalAlign="middle">
            <Switch
              checked={filterVisibleRegions}
              onChange={_ => {
                logAction(
                  SignupEventName.SIGNUP_FILTER_REGION_SWITCH,
                  'ui_selection',
                  'SignupCard',
                );
                setFilterVisibleRegions(!filterVisibleRegions);
              }}
              aria-label="filterRegionSwitch"
              label="Limit regions to listing visibility"
              value="1"
            />
            <Text marginLeft={12} color={Color.Gray70} size="small">
              <label htmlFor="filterRegionSwitch">
                <FormattedMessage id="Limit regions to listing availability" />
              </label>
            </Text>
          </Row>
        )}
        <Selector
          value={region}
          options={regionSelectList[cloud as Cloud]}
          placeholder={
            getAwsMarketplaceType() !== undefined
              ? formatMessage({ id: 'AWS Region' }) + '*'
              : formatMessage({ id: 'Region' }) + '*'
          }
          data-testid="region-selector"
          onChange={e => {
            logAction(SignupEventName.SIGNUP_SELECT_REGION, 'ui_selection', 'SignupCard', {
              oldRegion: region,
              newRegion: e.currentTarget.value,
              cloud,
            });
            setRegion(e.currentTarget.value);
          }}
          validation={val => !!val}
          required={regionBlurred}
          onBlur={() => setRegionBlurred(true)}
          width={'100%'}
        />
      </>
    );

    const regionLockedOption = (region: string) => {
      const regionLabel = regionSelectList[cloud as Cloud]?.find(r => r.value === region)?.label;
      return regionLabel ? <FormTextRow label="Region" text={regionLabel} /> : null;
    };

    return cloud
      ? !props.signupParams.region
        ? regionSelectionOptions
        : regionLockedOption(props.signupParams.region)
      : null;
  };

  const renderTermsLink = () => {
    return (
      <FormattedMessage
        id="Check here to indicate that you have read and agree to the terms of the <tos>Snowflake Self Service On Demand Terms</tos>."
        values={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          tos: function TOS(...chunks: any[]) {
            return (
              <TextLink href="https://www.snowflake.com/wp-content/uploads/2017/11/Self-Serve-On-Demand-TOS.pdf">
                {chunks}
              </TextLink>
            );
          },
        }}
      />
    );
  };

  const renderSecondStep = () => {
    return (
      <>
        <FormLayout layout="compact">
          {renderEdition()}
          {renderCloud()}
          {renderRegion()}
          <FormRow>
            <Column align="left" verticalAlign="top" marginRight={8}>
              <Checkbox
                name="terms-agreement"
                aria-label="terms-agreement"
                checked={termsAgreement}
                value="terms-agreement"
                onChange={() => setTermsAgreement(!termsAgreement)}
                required={true}
              />
            </Column>
            <Column align="left" verticalAlign="top">
              <Text size="small" color={Color.Gray70} marginBottom={8}>
                {renderTermsLink()}
              </Text>
              {termsBlurred && !termsAgreement ? (
                <Text color={Color.Red50} size="small">
                  <FormattedMessage id="Must agree to terms" />
                </Text>
              ) : null}
            </Column>
          </FormRow>
          {renderingOptions.showSalesAccessCheckbox && (
            <>
              <Row align="left" verticalAlign="top" marginTop={16}>
                <Column align="left" verticalAlign="top" marginRight={8}>
                  <Checkbox
                    name="sales-engineer-agreement"
                    aria-label="sales-engineer-agreement"
                    checked={salesEngineerAgreement}
                    value="sales-engineer-agreement"
                    onChange={() => setSalesEngineerAgreement(!salesEngineerAgreement)}
                  />
                </Column>
                <Column align="left" verticalAlign="top">
                  <Text size="small" color={Color.Gray70}>
                    <FormattedMessage
                      id="By checking, you authorize Snowflake sales engineers to have access to your
                        account during the sales process. Each SE will have a distinct login ID and
                        password. You can terminate this access at any time."
                    />
                  </Text>
                </Column>
              </Row>
              {salesAssistedBlurred && !salesEngineerAgreement ? (
                <Text marginTop={4} marginLeft={16} color={Color.Red50} size="small">
                  <FormattedMessage id="Must agree to sales engineer terms" />
                </Text>
              ) : null}
            </>
          )}
          <FormButton onClick={handleSubmit}>
            <FormattedMessage id="Get Started" />
          </FormButton>
        </FormLayout>
      </>
    );
  };

  const renderCard = () => {
    if (error) throw error; // triggers error boundary

    const defaultSuccessTitle = formatMessage({ id: "You're now signed up!" }).toUpperCase();
    const defaultSuccessSubtitle = (
      <FormattedMessage
        id="An email to activate your account has been sent to <emailformat>{emailaddress}</emailformat> (it may take a few minutes to arrive)."
        values={{
          emailaddress: email,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          emailformat: function EmailFormat(...chunks: any[]) {
            return (
              <Text style={{ color: '#7D44CF' }} weight="semi-bold">
                {chunks}
              </Text>
            );
          },
        }}
      />
    );

    //if errorCode is set, just display the error in the thank you card right away
    if (formSubmitted.value || !!errorCode) {
      return (
        <ThankYouCard
          errorCode={errorCode}
          accountCreationFinished={accountCreationFinished}
          successTitle={renderingOptions.successTitle ?? defaultSuccessTitle}
          successSubtitle={renderingOptions.successSubtitle ?? defaultSuccessSubtitle}
          skipSurvey={renderingOptions.skipSurvey}
        />
      );
    } else {
      if (step === 1) {
        return <CardTemplate>{renderFirstStep()}</CardTemplate>;
      } else {
        return <CardTemplate>{renderSecondStep()}</CardTemplate>;
      }
    }
  };

  return renderCard();
};
