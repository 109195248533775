import { Text } from '@snowflake/core-ui';
import { SignupApiErrorCode } from '@signup/shared';
import { default as React, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardTemplate } from '../shared/CardTemplate';
import { checkmarkIcon, DotsLoadingIndicator, exclamationIcon } from '../shared/shared';
import { SurveyCard } from '../SurveyCard/SurveyCard';
import { ThankYouCardBody } from './ThankYouCardBody';

export interface ThankYouCardProps {
  errorCode?: SignupApiErrorCode;
  accountCreationFinished: boolean;
  successTitle: string;
  successSubtitle: React.ReactChild;
  skipSurvey?: boolean;
}

export const ThankYouCard = (props: ThankYouCardProps) => {
  const { formatMessage } = useIntl();
  const [isSurveyResponseSubmitted, setIsSurveyResponseSubmitted] = useState(false);

  const handleSurveySubmission = () => {
    setIsSurveyResponseSubmitted(true);
  };

  const setErrorMessageText = (error: SignupApiErrorCode): React.ReactChild => {
    switch (error) {
      case SignupApiErrorCode.INVALID_SALES_ASSISTED_URL:
        return (
          <FormattedMessage id="This url has an error. Please check with your Snowflake representative to get a new url link." />
        );
      case SignupApiErrorCode.SUPPRESSED_COUNTRY:
        return (
          <Text>
            <FormattedMessage id="We are unable to provision a Snowflake account in your country due to certain restrictions." />
            <br />
            <br />
            <FormattedMessage id="Please do not contact Snowflake Technical Support, as they will not be able to assist you." />
          </Text>
        );
      case SignupApiErrorCode.SUPPRESSED_EMAIL:
        return (
          <Text>
            <FormattedMessage id="We are unable to verify the email address submitted." />
            <br />
            <br />
            <FormattedMessage id="Please do not contact Snowflake Technical Support, as they will not be able to assist you." />
          </Text>
        );
      case SignupApiErrorCode.SUPPRESSED_GENERIC:
        return (
          <Text>
            <FormattedMessage id="We are unable to provision a Snowflake account due to certain restrictions." />
            <br />
            <br />
            <FormattedMessage id="Please do not contact Snowflake Technical Support, as they will not be able to assist you." />
          </Text>
        );
      case SignupApiErrorCode.GENERIC_ERROR:
        return (
          <FormattedMessage id="Looks like we are having an issue provisioning your account. Please try again later." />
        );
      default:
        return (
          <FormattedMessage id="Looks like we are having an issue provisioning your account. Please try again later." />
        );
    }
  };

  const { thankYouTitle, thankYouSubtitle, thankYouIcon } = useMemo(() => {
    // Error
    if (!!props.errorCode) {
      return {
        thankYouTitle: formatMessage({ id: 'Whoops!' }),
        thankYouSubtitle: setErrorMessageText(props.errorCode),
        thankYouIcon: exclamationIcon,
      };
    }
    // Success
    else if (props.accountCreationFinished) {
      return {
        thankYouTitle: props.successTitle,
        thankYouSubtitle: props.successSubtitle,
        thankYouIcon: checkmarkIcon,
      };
    }
    // Loading
    else {
      return {
        thankYouTitle: formatMessage({ id: 'Account Setup in Progress' }),
        thankYouSubtitle: (
          <FormattedMessage id="Your account setup may take a few minutes. While you're waiting, learn more about Snowflake." />
        ),
        thankYouIcon: <DotsLoadingIndicator />,
      };
    }
  }, [
    formatMessage,
    props.errorCode,
    props.accountCreationFinished,
    props.successTitle,
    props.successSubtitle,
  ]);

  if (!props.skipSurvey && !isSurveyResponseSubmitted) {
    return <SurveyCard markAsSubmitted={handleSurveySubmission} />;
  }

  const renderThankYouCardWithSurvey = () => {
    return (
      <>
        <FormattedMessage id="Thank you for your response!" />
        <ThankYouCardBody />
      </>
    );
  };
  return (
    <CardTemplate title={thankYouTitle} subtitle={thankYouSubtitle} aux={thankYouIcon}>
      {!props.skipSurvey ? renderThankYouCardWithSurvey() : <ThankYouCardBody />}
    </CardTemplate>
  );
};
