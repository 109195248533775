import { Block, Color, Shimmer, Text } from '@snowflake/core-ui';
import { ListingInformation } from '@signup/shared';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import * as uuid from 'uuid';
import { useSignupPageContext } from '../../pages/SignupContext';
import { MarketingTextType } from '../../types';

export const InfoListItem = (props: { children: React.ReactNode }) => {
  return (
    <Block display="flex" justifyContent="space-between" verticalAlign="top" marginBottom={12}>
      <Block marginTop="6px" marginRight={16}>
        <img src="/static/images/rectangle-bullet.svg" />
      </Block>
      {props.children}
    </Block>
  );
};

export const InfoText = (props: { children: React.ReactNode }) => {
  return (
    <Text color={Color.White} size="large" lineHeight="30px" marginBottom={12} width="100%">
      {props.children}
    </Text>
  );
};

export interface InfoListProps {
  listingInformation?: ListingInformation;
  isLoading?: boolean;
}

export const InfoList = (props: InfoListProps) => {
  const { renderingOptions } = useSignupPageContext();

  const getLoadingOrListingMarketingText = React.useCallback(() => {
    return [
      <Block key="1" display="flex" flexWrap="wrap">
        <FormattedMessage
          id="Receive real time updates from <providerName></providerName> automatically"
          description="Indicates who provides updates for a package. Contains a blank for the author's name."
          values={{
            providerName: function ProviderName() {
              if (props.isLoading) {
                return (
                  <Shimmer
                    role="progressbar"
                    aria-busy={true}
                    height={20}
                    width="15%"
                    marginTop={4}
                    marginLeft={4}
                    marginRight={4}
                  />
                );
              } else {
                return props.listingInformation?.providerName;
              }
            },
          }}
        />
      </Block>,
      <FormattedMessage key="2" id="Explore data, services and more without ETL" />,
      <FormattedMessage
        key="3"
        id="Scale your critical data workloads instantly, elastically, and near-infinitely across public clouds"
      />,
    ];
  }, [props.isLoading, props.listingInformation]);

  const marketingText = React.useMemo(() => {
    if (renderingOptions.marketingTextType === MarketingTextType.LISTING) {
      return getLoadingOrListingMarketingText();
    } else if (renderingOptions.marketingTextType === MarketingTextType.BASIC) {
      return renderingOptions.marketingInfoList;
    } else {
      return [];
    }
  }, [
    renderingOptions.marketingTextType,
    renderingOptions.marketingInfoList,
    getLoadingOrListingMarketingText,
  ]);

  const bulletedMarketingText = marketingText.map(text => {
    return (
      <InfoListItem key={uuid.v4()}>
        <InfoText>{text}</InfoText>
      </InfoListItem>
    );
  });

  return (
    <Block className="trial-info-list" marginBottom={24}>
      {bulletedMarketingText}
      {!renderingOptions.hideCertifications && (
        <InfoListItem>
          <Block display="flex" flexDirection="column">
            <InfoText>
              <FormattedMessage id="Snowflake is HIPAA, PCI DSS, SOC 1 and SOC 2 Type 2 compliant, and FedRAMP Authorized" />
            </InfoText>
            <Block
              className="trial-info-list-certification-icons"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="85%"
              marginTop="12px"
            >
              <img src="/static/images/compliance/white/pci.png" height="40px" alt="PCI" />
              <img src="/static/images/compliance/white/hipaa.png" height="40px" alt="HIPAA" />
              <img src="/static/images/compliance/white/soc2.png" height="40px" alt="SOC 2" />
              <img src="/static/images/compliance/white/fedramp.png" height="40px" alt="FedRAMP" />
            </Block>
          </Block>
        </InfoListItem>
      )}
    </Block>
  );
};
