import { AwsMarketplaceType, SignupFlowType } from '@signup/shared';
import { getSignupFlowTypeWithoutListener } from './configurations';

type AwsSignupFlow =
  | SignupFlowType.AwsOnDemand
  | SignupFlowType.AwsCapacity
  | SignupFlowType.AwsOnDemandAddSubscription;

const SignupFlowToAwsMarketPlace: Record<AwsSignupFlow, AwsMarketplaceType> = {
  [SignupFlowType.AwsOnDemand]: AwsMarketplaceType.ON_DEMAND,
  [SignupFlowType.AwsCapacity]: AwsMarketplaceType.CAPACITY,
  [SignupFlowType.AwsOnDemandAddSubscription]: AwsMarketplaceType.ON_DEMAND_ADD_SUBSCRIPTION,
};

export const getAwsMarketplaceType = (): AwsMarketplaceType | undefined => {
  const signupFlowType = getSignupFlowTypeWithoutListener();

  if (
    signupFlowType === SignupFlowType.AwsOnDemand ||
    signupFlowType === SignupFlowType.AwsCapacity ||
    signupFlowType === SignupFlowType.AwsOnDemandAddSubscription
  ) {
    return SignupFlowToAwsMarketPlace[signupFlowType];
  }

  return undefined;
};
