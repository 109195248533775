import { Color, Text } from '@snowflake/core-ui';
import * as React from 'react';
import { useSignupPageContext } from '../../pages/SignupContext';
import { MarketingTextType } from '../../types';

export const CardMarketingTitle = () => {
  const { renderingOptions } = useSignupPageContext();
  return (
    <Text
      className="trial-card-marketing-text"
      fontSize={20}
      color={Color.White}
      lineHeight="30px"
      marginBottom={24}
    >
      {renderingOptions.marketingTextType !== MarketingTextType.OMIT &&
        renderingOptions.cardMarketingTitle}
    </Text>
  );
};
