import { Block, BlockProps, Color, Column } from '@snowflake/core-ui';
import cookies from 'browser-cookies';
import React from 'react';
import * as configs from './configurations';

export const BEACH_COOKIE = 'snowflake_growth_channel';
const beachCookieValue = cookies.get(BEACH_COOKIE) || null;
const clearBeachCookie = () => {
  cookies.erase(BEACH_COOKIE);
  window.location.replace(window.location.origin + window.location.pathname);
};

const MANUAL_OVERRIDE_COOKIE = '_expoverride';
let cookieValue: { [key: string]: string } | null = null;
try {
  cookieValue = JSON.parse(cookies.get(MANUAL_OVERRIDE_COOKIE) || '');
  if (typeof cookieValue !== 'object') {
    cookieValue = null;
  }
} catch {
  // ignore;
}

const clearExpCookie = (expName: string) => {
  if (cookieValue) {
    delete cookieValue[expName];
    cookies.set(MANUAL_OVERRIDE_COOKIE, JSON.stringify(cookieValue));
  } else {
    cookies.erase(MANUAL_OVERRIDE_COOKIE);
  }
  window.location.replace(window.location.origin + window.location.pathname);
};

export const InternalHeaders = () => {
  const blocks: JSX.Element[] = [];
  const height = 20;

  const commonProps: BlockProps = {
    cursor: 'pointer',
    display: 'flex',
    height,
    justifyContent: 'center',
    opacity: 0.4,
    width: '100%',
    zIndex: 10,
  };

  if (beachCookieValue) {
    blocks.push(
      <Block
        key="beach-cookie"
        onClick={clearBeachCookie}
        backgroundColor={Color.Blue50}
        color={Color.White}
        {...commonProps}
      >
        {beachCookieValue} ({configs.getClientVersion()}) (Click to Clear)
      </Block>,
    );
  }

  if (cookieValue) {
    for (const [idx, [expName, groupName]] of Object.entries(cookieValue).entries()) {
      blocks.push(
        <Block
          key={expName}
          onClick={() => clearExpCookie(expName)}
          backgroundColor={idx % 2 === 1 ? Color.Blue50 : Color.Green60}
          color={Color.White}
          {...commonProps}
        >
          Experiment override ({expName}): {groupName} (Click to Clear)
        </Block>,
      );
    }
  }

  if (!blocks) {
    return null;
  }

  return (
    <Column position="absolute" height={height * blocks.length} top="0" left="0" right="0">
      {blocks}
    </Column>
  );
};
