import { Block } from '@snowflake/core-ui';
import {
  AwsMarketplaceType,
  Cloud,
  AwsMpAccount,
  ABExperimentName,
  SignupFlowType,
} from '@signup/shared';
import queryString from 'query-string';
import * as React from 'react';
import { AwsErrorCard } from '../components/Cards/AwsErrorCard';
import { AwsCard } from '../components/Cards/forms/AwsCard';
import { AzureCard } from '../components/Cards/forms/AzureCard';
import { GcpCard } from '../components/Cards/forms/GcpCard';
import { SignupCard } from '../components/Cards/forms/SignupCard/SignupCard';
import { PageNotFoundCard } from '../components/Cards/PageNotFoundCard';
import { CertificationsFooter } from '../components/CertificationsFooter';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { CardMarketingTitle } from '../components/MarketingText/CardMarketingTitle';
import { InfoList } from '../components/MarketingText/InfoList';
import { Title } from '../components/MarketingText/Title';
import { InternalHeaders } from '../utils/InternalHeaders';
import { useSignupPageContext } from './SignupContext';
import { SignupPageProps } from './SignupPageRoot';
import { MarketingParams, parseQueryParams, SignupParams } from '../utils/ParseQueryParams';
import { useListingInformation } from './UseListingInformation';
import * as configs from '../utils/configurations';
import { getAwsMarketplaceType } from '../utils/signupFlowTypeUtils';
import { SignupCardV2 } from '../components/Cards/forms/SignupCardV2/SignupCardV2';
import { MarketingTextType } from '../types';
import { useSignupFlowType } from '../utils/useSignupFlowType';

const recaptchaRef = React.createRef();

export const SignupPage = (props: SignupPageProps) => {
  const { videoShowing, renderingOptions } = useSignupPageContext();

  const signupFlowType = useSignupFlowType();
  const awsMarketPlaceType = getAwsMarketplaceType();

  const [signupParams, marketingParams]: [SignupParams, MarketingParams] = React.useMemo(() => {
    return parseQueryParams(props.location?.search);
  }, [props.location]);

  const { listingInformation, isListingInfoLoading } = useListingInformation(signupParams.listing);

  if (!!awsMarketPlaceType) signupParams.cloud = Cloud.AWS;

  const isAwsError = !!awsMarketPlaceType && !signupParams.ampt;

  const card = React.useMemo(() => {
    if (props.pageNotFound || signupFlowType === SignupFlowType.Unknown) {
      return <PageNotFoundCard />;
    } else if (isAwsError) {
      return <AwsErrorCard />;
    } else if (
      awsMarketPlaceType === AwsMarketplaceType.CAPACITY ||
      awsMarketPlaceType === AwsMarketplaceType.ON_DEMAND_ADD_SUBSCRIPTION
    ) {
      return (
        <AwsCard
          signupParams={signupParams}
          marketingParams={marketingParams}
          formId={props.formId}
          recaptchaRef={recaptchaRef}
          awsMpAccount={signupParams.awsmp || AwsMpAccount.SFC_INC_MARKETPLACE_US}
        />
      );
    } else if (signupFlowType === SignupFlowType.Azure) {
      return (
        <AzureCard
          signupParams={signupParams}
          marketingParams={marketingParams}
          formId={props.formId}
          recaptchaRef={recaptchaRef}
          queryParams={queryString.parse(props.location?.search)}
        />
      );
    } else if (signupFlowType === SignupFlowType.Gcp) {
      return <GcpCard />;
    } else if (configs.getExperimentGroup(ABExperimentName.SIGNUP_FORM_REWORK) === 'ENABLED') {
      return (
        <SignupCardV2
          signupParams={signupParams}
          marketingParams={marketingParams}
          formId={props.formId}
          recaptchaRef={recaptchaRef}
        />
      );
    } else {
      return (
        <SignupCard
          signupParams={signupParams}
          marketingParams={marketingParams}
          formId={props.formId}
          recaptchaRef={recaptchaRef}
        />
      );
    }
  }, [
    props.pageNotFound,
    props.formId,
    props.location?.search,
    signupFlowType,
    awsMarketPlaceType,
    signupParams,
    marketingParams,
    isAwsError,
  ]);

  const marketingTextRequired = React.useMemo(
    () =>
      renderingOptions.marketingTextType !== MarketingTextType.OMIT &&
      !props.pageNotFound &&
      !isAwsError,

    [renderingOptions.marketingTextType, props.pageNotFound, isAwsError],
  );

  const renderLeftText = () => {
    if (marketingTextRequired) {
      return (
        <Block
          className="trial-left-container"
          display="flex"
          flexDirection="column"
          justifyContent="top"
        >
          <Title listingInformation={listingInformation} isLoading={isListingInfoLoading} />
          <InfoList listingInformation={listingInformation} isLoading={isListingInfoLoading} />
        </Block>
      );
    }
  };

  return (
    <Block className="trial-page-container">
      <InternalHeaders />
      <Header />
      <Block className="trial-responsive-flex-container">
        {renderLeftText()}
        <Block
          className="trial-right-container"
          display="flex"
          flexDirection="column"
          justifyContent="top"
          maxWidth={videoShowing.value ? '736px' : '420px'}
        >
          {marketingTextRequired && <CardMarketingTitle />}
          {card}
        </Block>
      </Block>
      {!marketingTextRequired && <CertificationsFooter />}
      <Footer recaptchaRef={recaptchaRef} pageNotFound={props.pageNotFound} />
    </Block>
  );
};
