import {
  ABExperimentName,
  DEFAULT_GROUP,
  DeploymentMap,
  DeploymentMetadata,
  FeatureFlag,
  SignupFlowType,
} from '@signup/shared';

const typedWindowInstance = window as any as {
  trial?: {
    clientVersion?: string;
    recaptchaSiteKey?: string;
    isoCode?: string;
    features?: { [key: string]: boolean };
    experimentGroups?: { [key: string]: string };
    coordinates?: [number, number] | [];
    referrerUrl?: string;
    deployments?: DeploymentMap;
    signupFlowType?: SignupFlowType;
  };
  build?: {
    num?: number;
    time?: string;
    version?: string;
    env?: 'test' | 'production' | 'development';
    deploymentGroup?: 'PROD_DEPLOYMENTS' | 'DEV_DEPLOYMENTS';
  };
  dataLayer: Array<{ event: string } | { 'gtm.start': number; event: 'gtm.js' }>;
};

export function isFeatureEnabled(feature: FeatureFlag): boolean {
  return typedWindowInstance?.trial?.features?.[feature] === true;
}

export function getIsoCode(): string {
  return typedWindowInstance?.trial?.isoCode || '';
}

export function getReferrerUrl(): string {
  return typedWindowInstance?.trial?.referrerUrl || '';
}

export function getRecaptchaSiteKey(): string {
  return typedWindowInstance?.trial?.recaptchaSiteKey || '';
}

export function getDeploymentGroup(): 'PROD_DEPLOYMENTS' | 'DEV_DEPLOYMENTS' {
  return typedWindowInstance?.build?.deploymentGroup || 'PROD_DEPLOYMENTS';
}

export function getCoordinates(): [number, number] | [] {
  return typedWindowInstance?.trial?.coordinates || [];
}

export function getBuildEnv(): 'test' | 'production' | 'development' {
  return typedWindowInstance?.build?.env || 'production';
}

export function getExperimentGroup(experiment: ABExperimentName): string {
  return typedWindowInstance?.trial?.experimentGroups?.[experiment] || DEFAULT_GROUP;
}

export function getDeployments(): DeploymentMap {
  return Object.freeze((typedWindowInstance?.trial?.deployments as DeploymentMap) || {});
}

export function getDeploymentsList(): readonly DeploymentMetadata[] {
  return Object.freeze(Object.values(getDeployments()));
}

export function getClientVersion(): string {
  return typedWindowInstance?.trial?.clientVersion || '';
}

export function getDataLayer(): Array<
  { event: string } | { 'gtm.start': number; event: 'gtm.js' }
> {
  return typedWindowInstance.dataLayer;
}

export function getSignupFlowTypeWithoutListener(): SignupFlowType {
  return typedWindowInstance?.trial?.signupFlowType || SignupFlowType.Unknown;
}

export function setSignupFlowType(signupFlowType: SignupFlowType): void {
  if (typedWindowInstance?.trial?.signupFlowType) {
    typedWindowInstance.trial.signupFlowType = signupFlowType;
    window.dispatchEvent(new Event('signupFlowTypeChange'));
  }
}

export function getExperimentGroups(): { [key: string]: string } {
  return typedWindowInstance?.trial?.experimentGroups || {};
}

export function getFeatureFlags(): { [key: string]: boolean } {
  return typedWindowInstance?.trial?.features || {};
}
