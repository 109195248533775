import { Block, Color, Shimmer, Text } from '@snowflake/core-ui';
import { ListingInformation } from '@signup/shared';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSignupPageContext } from '../../pages/SignupContext';
import { MarketingTextType } from '../../types';

export interface TitleProps {
  listingInformation?: ListingInformation;
  isLoading: boolean;
}

/*
The listing title and provider information is loaded using a hook that fetches the listing information.
It is passed by props to the Title component. The Title component will display a shimmering effect while
the information is being loaded. Once the information is loaded, the Title component will display the
title and provider information. If the listingInformation is not available, the Title component will
display the default title.
*/

export const Title = (props: TitleProps) => {
  const { renderingOptions } = useSignupPageContext();

  const shouldUseListingInformation =
    renderingOptions.marketingTextType === MarketingTextType.LISTING;

  const listingIndicatorTitle = props.listingInformation ? (
    <Text allCaps={true} fontSize={20} style={{ color: '#e2e2e5' }}>
      <FormattedMessage id="Snowflake Marketplace Listing" />
    </Text>
  ) : (
    <></>
  );

  const providerName = (
    <Text
      allCaps={true}
      fontSize={20}
      marginBottom={32}
      color={Color.White}
      display="flex"
      flexDirection="row"
      width="100%"
    >
      <FormattedMessage
        id="By <providerName></providerName>"
        description="Displays the author of a certain package. Contains a blank that is filled by the author name."
        values={{
          providerName: function ProviderName() {
            if (props.isLoading) {
              return (
                <Shimmer
                  role="progressbar"
                  aria-busy={true}
                  marginLeft={4}
                  height={20}
                  width="15%"
                />
              );
            } else if (props.listingInformation) {
              // Ideally we wouldn't need the marginLeft, but the flex we need for the shimmer messes with the spacing
              // between "By" and the provider name.
              return (
                <span style={{ marginLeft: 4, fontWeight: 'bold' }}>
                  {props.listingInformation?.providerName}
                </span>
              );
            }
          },
        }}
      />
    </Text>
  );

  const title = React.useMemo(() => {
    if (shouldUseListingInformation) {
      if (props.isLoading) {
        return (
          <Shimmer role="progressbar" aria-busy={true} height={50} marginBottom={8} marginTop={8} />
        );
      } else if (props.listingInformation) {
        return <>{props.listingInformation.title}</>;
      }
    } else if (renderingOptions.marketingTextType === MarketingTextType.BASIC) {
      return renderingOptions.mainMarketingTitle;
    } else {
      return <></>;
    }
  }, [
    renderingOptions.marketingTextType,
    renderingOptions.mainMarketingTitle,
    shouldUseListingInformation,
    props.isLoading,
    props.listingInformation,
  ]);

  return (
    <Block>
      {listingIndicatorTitle}
      <Text
        className="trial-title"
        allCaps={true}
        fontSize={50}
        color={Color.White}
        lineHeight="57px"
        weight="bold"
        marginBottom={shouldUseListingInformation ? 0 : 24}
      >
        {title}
      </Text>
      {shouldUseListingInformation && providerName}
    </Block>
  );
};
